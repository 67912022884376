import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import _ from 'lodash';
import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Loader from '~/components/common/Loader/Loader';
import { ThemedTab, ThemedInput, ThemedRadio, ThemedCheckbox } from '~/components/common/themed';
import { URL_REGEXP, EMAIL_REGEXP, FQDN_REGEXP } from '~/config';
import { assetsActions } from '~/store/assets';
import { customizeActions, ICustomizeForm } from '~/store/customize';
import { modalActions } from '~/store/modal';
import { API_URL } from '../../../config';
import './CustomizeForm.scss';
import uuidv4 from 'uuid/v4';
import { Branding, Branding } from "~/branding";
import Select from 'react-select';
import PartnerType from './PartnerType';

const CUSTOMIZE_LOGO_FILE = 'CUSTOMIZE_LOGO_FILE';
const CUSTOMIZE_LOGO_URL = 'CUSTOMIZE_LOGO_URL';

interface DispatchProps {
  closeModal: () => void;
  createCustomize: (form: ICustomizeForm, partnerId?: string) => Promise<any>;
  getCustomize: (partnerId?: string) => Promise<any>;
  getDownloadURL: (bucket: string, filename: string) => Promise<any>;
  getUploadURL: (bucket: string, filename: string) => Promise<any>;
  resetCustomize: (partnerId?: string) => Promise<any>;
  updateCustomize: (form: ICustomizeForm, partnerId?: string) => Promise<any>;
  uploadFile: (file: string) => Promise<any>;
  uploadFileAndgetURL: (file: string) => Promise<any>;
};
let isFaviconUploaded = false;
interface OwnProps extends RouteComponentProps {
  activeTab: string;
  backTab: (e: BaseSyntheticEvent) => void;
  nextTab: (e: BaseSyntheticEvent) => void;
  previewCallback: () => void;
};

interface StateProps {
  isFetching: boolean;
  customize: any;
  uploadURL: string;
  previewImage: string;
  favionImage: string;
  partnerId: string;
  partnerName: string;
  authenticatedUser: any;
  errors: string[];
};

type State = {
  color: string,
  emailTemplateMode: string;
  emailCCTemplateMode: string;
  form: ICustomizeForm,
  isColorPickerOpen: boolean,
  isFetching: boolean,
  isPreviewActive: boolean,
  logoType: string,
  faviconType: string,
  previewUrl: string | null
};

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createCustomize: customizeActions.createCustomize,
  getCustomize: customizeActions.getCustomize,
  getDownloadURL: assetsActions.getDownloadURL,
  getUploadURL: assetsActions.getUploadURL,
  resetCustomize: customizeActions.resetCustomize,
  updateCustomize: customizeActions.updateCustomize,
  uploadFile: assetsActions.uploadFile,
  uploadFileAndgetURL: assetsActions.uploadFileAndgetURL
};

const mapStateToProps = (state: any) => ({
  isFetching: state.customize.isFetching,
  customize: state.customize.customize,
  uploadURL: state.assets.uploadURL,
  partnerId: state.modal.modalProps.partnerId,
  partnerName: state.partners.partner ? state.partners.partner.name : (state.customers && state.customers.customers[0]) ? state.customers.customers[0].reseller_name : '',
  authenticatedUser: state.auth.authenticatedUser,
  errors: state.customize.errors,
});

const emailVariables = {
  'keywords': [
    'appName',
    'commandCenterName',
    'partner',
    'support_email',
    'support_phone'
  ],
  'templates': [
    {
      'previewKey': 'cc_email_subject_preview',
      'editKey': 'cc_email_subject'
    },
    {
      'previewKey': 'cc_invitation_email_text_preview',
      'editKey': 'cc_invitation_email_text'
    },
    {
      'previewKey': 'email_subject_preview',
      'editKey': 'email_subject'
    },
    {
      'previewKey': 'invitation_email_text_preview',
      'editKey': 'invitation_email_text'
    }
  ]
};

const validateKeys = [
  'invitation_email_text',
  'email_subject',
  'cc_invitation_email_text',
  'cc_email_subject',
  'application_name',
  'cc_name',
  'support_email',
  'support_phone'
]

const whiteLabelEmailTemplate: any = {
  partner: {
    variableName: '{partner_name}',
    defaultValue: `${Branding.COMPANY_NAME}`,
    propKey: 'partnerName'
  },
  appName: {
    variableName: '{app_name}',
    defaultValue: `${Branding.APP_NAME}`,
    key: 'application_name'
  },
  commandCenterName: {
    variableName: '{cc_name}',
    defaultValue: `${Branding.ADMIN_APP_NAME}`,
    key: 'cc_name'
  },
  support_email: {
    variableName: '{support_email}',
    defaultValue: `${Branding.SUPPORT_EMAIL}`,
    key: 'support_email'
  },
  support_phone: {
    variableName: '{support_phone}',
    defaultValue: `${Branding.SUPPORT_PHONE_NUMBER}`,
    key: 'support_phone',
    formatPhone: true
  },
};

const FormInitialData: any = {
  application_name: '',
  cc_domain_name: '',
  cc_name: '',
  cc_email_subject: `Welcome to ${whiteLabelEmailTemplate.partner.variableName} ${whiteLabelEmailTemplate.appName.variableName} ${whiteLabelEmailTemplate.commandCenterName.variableName}, Let\'s Get Started!`,
  cc_email_subject_preview: '',
  cc_invitation_email_text: `Thank you for choosing ${whiteLabelEmailTemplate.partner.variableName} ${whiteLabelEmailTemplate.appName.variableName}, a cutting-edge, cloud-based messaging application. You’re the designated administrator for your company. The first step is to log in and set your personal password.


After you set your personal password, you’ll want to add users. Email invitations will go out automatically to each user. Come back to the ${whiteLabelEmailTemplate.appName.variableName} ${whiteLabelEmailTemplate.commandCenterName.variableName} to perform administrative tasks such as adding and removing users, and ordering new phone numbers.


For assistance, email ${whiteLabelEmailTemplate.support_email.variableName} or text/call ${whiteLabelEmailTemplate.support_phone.variableName}.


Thank you,
The ${whiteLabelEmailTemplate.partner.variableName} Team`,
  cc_invitation_email_text_preview: '',
  email_subject: `Welcome to ${whiteLabelEmailTemplate.partner.variableName} ${whiteLabelEmailTemplate.appName.variableName}, Let\'s Get Started!`,
  email_subject_preview: '',
  domain_name: '',
  default_from_email: '',
  default_from_name: '',
  invitation_email_text: `Thank you for choosing ${whiteLabelEmailTemplate.partner.variableName} ${whiteLabelEmailTemplate.appName.variableName}, a cutting-edge, cloud-based messaging application. The first step is to log in and set your personal password.

Once logged in, you'll be asked to set your personal password. ${whiteLabelEmailTemplate.appName.variableName} was designed to be powerful and versatile, yet simple. The best way to familiarize yourself is to use it. You may want to start with these features:

Automatic Responses
Messaging responses that are sent automatically when certain keywords are entered, when you're out on vacation, or when other criteria are met.


Many-to-many Number Assignments
Assign multiple users to one number or multiple numbers to one user - allowing infinite ways to configure communication channels.


Message Archive
All messages are archived and retrievable for historical bookkeeping and auditing purposes.


For assistance, email ${whiteLabelEmailTemplate.support_email.variableName} or text/call ${whiteLabelEmailTemplate.support_phone.variableName}


Thank you,
The ${whiteLabelEmailTemplate.partner.variableName} Team`,
  invitation_email_text_preview: '',
  logo_url: '',
  primary_css_color: '',
  show_logo_cc: true,
  show_logo_mv: true,
  show_login_logo_cc: true,
  show_login_logo_mv: true,
  show_copywrite: true,
  show_help_link: true,
  show_terms_of_use_link: true,
  show_privacy_policy_link: true,
  smtp_host: '',
  smtp_password: '',
  smtp_port: undefined,
  smtp_user: '',
  support_email: '',
  support_phone: '',
  favicon: '',
  partner_name: '',
  privacy_policy_link_url: undefined,
  welcome_message: `Hi [First Name] [Last Name],
Welcome to [Application Name]. Receive text messages in your browser portal or by downloading the ${Branding.APP_NAME} app from the App Store on iOS or Google Play Store on Android.`,
  partner_type: 'Agent'
};

const FormInitialValid = {
  application_name: true,
  cc_domain_name: true,
  cc_name: true,
  cc_email_subject: true,
  cc_invitation_email_text: true,
  email_subject: true,
  domain_name: true,
  default_from_email: true,
  default_from_name: true,
  invitation_email_text: true,
  logo_url: true,
  primary_css_color: true,
  smtp_host: true,
  smtp_password: true,
  smtp_port: true,
  smtp_user: true,
  support_email: true,
  support_phone: true,
  favicon: true
};

class CustomizeForm extends PureComponent<Props, State> {

  state = {
    emailTemplateMode: 'edit',
    emailCCTemplateMode: 'edit',
    previewImage: null,
    favionImage: null,
    previewUrl: null,
    color: '',
    logoType: CUSTOMIZE_LOGO_FILE,
    faviconType: CUSTOMIZE_LOGO_FILE,
    isColorPickerOpen: false,
    isPreviewActive: false,
    isFetching: false,
    form: FormInitialData
  }

  componentDidMount() {
    this.props.getCustomize(this.props.partnerId).then(this.loadCustomize);
  }

  loadCustomize = () => {
    if (this.props.customize && this.props.customize.hasOwnProperty('currentWl')) {
      this.setState({ form: { ...this.state.form, ...this.props.customize.currentWl } });
      this.setState({ form: { ...this.state.form, ...this.handleEmailTemplateReverse() } });
    }
    this.setState({ form: { ...this.state.form, ...this.handleEmailTemplate('', '', true) } });
  }

  handleEmailTemplateReverse = () => {
    let templates: any = {};
    emailVariables.templates.forEach(template => {
      let value = this.state.form[template.editKey];
      emailVariables.keywords.forEach(keyword => {
        let pattern = '';
        if (whiteLabelEmailTemplate[keyword].key) {
          pattern = this.state.form[whiteLabelEmailTemplate[keyword].key] ? this.state.form[whiteLabelEmailTemplate[keyword].key] : whiteLabelEmailTemplate[keyword].defaultValue;
          if (whiteLabelEmailTemplate[keyword].formatPhone) {
            pattern = this.formatPhoneNumber(pattern.replace('+', ''));
          }
        } else {
          pattern = this.props[whiteLabelEmailTemplate[keyword].propKey];
        }
        value = value.replace(new RegExp(pattern, 'g'), whiteLabelEmailTemplate[keyword].variableName);
        value = value.replace(new RegExp(whiteLabelEmailTemplate[keyword].defaultValue, 'g'), whiteLabelEmailTemplate[keyword].variableName);
      });
      templates[template.editKey] = value;
    });
    return templates;
  }

  formatPhoneNumber = (phoenNumber: any) => {
    return phoenNumber.substr(0, 3)
      + ' ' + phoenNumber.substr(3, 3)
      + ' ' + phoenNumber.substr(6, phoenNumber.length);
  };

  handleReset = () => {
    this.props.resetCustomize(this.props.partnerId).then(() => this.setState({ form: FormInitialData }, this.props.closeModal));
  }

  handleEmailTemplate = (id: string, inputValue: string, checkALL?: boolean) => {
    if (!checkALL && !validateKeys.includes(id)) {
      return {};
    }
    let templates: any = {};
    emailVariables.templates.forEach(template => {
      let value = id === template.editKey ? inputValue : this.state.form[template.editKey];
      emailVariables.keywords.forEach(keyword => {
        let replaceValue = '';
        if (whiteLabelEmailTemplate[keyword].key === id) {
          replaceValue = inputValue ? inputValue : whiteLabelEmailTemplate[keyword].defaultValue;
        } else {
          if (whiteLabelEmailTemplate[keyword].key) {
            replaceValue = this.state.form[whiteLabelEmailTemplate[keyword].key] ? this.state.form[whiteLabelEmailTemplate[keyword].key] : whiteLabelEmailTemplate[keyword].defaultValue;
          } else {
            replaceValue = this.props[whiteLabelEmailTemplate[keyword].propKey];
          }
        }
        if (whiteLabelEmailTemplate[keyword].formatPhone) {
          replaceValue = this.formatPhoneNumber(replaceValue);
        }
        value = value.replace(new RegExp(whiteLabelEmailTemplate[keyword].variableName, 'g'), replaceValue);
      });
      templates[template.previewKey] = value;
    });
    return templates;
  }

  handleSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Send form
    this.setState({ ...this.state, isFetching: true });
    if (this.props.uploadURL !== '' && this.state.form.logo_url !== '') {
      await this.props.uploadFile(this.state.form.logo_url);
      await this.props.getDownloadURL('', this.state.form.logo_url.name);
    }

    if (isFaviconUploaded && this.state.form.favicon !== '') {
      await this.props.uploadFileAndgetURL(this.state.form.favicon).then(res => {
        this.state.form.favicon = res.data.data;
      });
    }
    emailVariables.templates.forEach(template => {
      this.state.form[template.editKey] = this.state.form[template.previewKey];
    });
    this.state.form.partner_name = this.props.partnerName;
    this.setState({ ...this.state, isFetching: false });
    if (_.isEmpty(this.props.customize)) {
      this.props.createCustomize(this.state.form, this.props.partnerId).then(this.props.closeModal);
    } else {
      this.props.updateCustomize(this.state.form, this.props.partnerId).then(this.props.closeModal);
    }
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value, ...this.handleEmailTemplate(id, value) } }, () => {
      console.log('this.state ', this.state)
    });
  }

  handleCheckboxChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id } = e.target as HTMLInputElement;
    this.setState((prevState) => ({ form: { ...this.state.form, [id]: !prevState.form[id] } }));
  }

  handleRadio = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.setState({ logoType: value });
  }

  handleRadio1 = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.setState({ faviconType: value });
  }

  handleClickColorPicker = () => {
    this.setState(prevState => ({ isColorPickerOpen: !prevState.isColorPickerOpen }));
  };

  handleCloseColorPicker = () => {
    this.setState({ isColorPickerOpen: false });
  }

  handleChangeComplete = (color: ColorResult) => {
    this.setState({ form: { ...this.state.form, primary_css_color: color.hex } });
  };

  handleFileChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, files } = e.target as HTMLInputElement;
    if (files && files[0]) {
      this.setState({ form: { ...this.state.form, [id]: files && files[0] } }, () => {
        if (id === 'favicon') {
          isFaviconUploaded = true;
        } else {
          this.props.getUploadURL('', files[0].name);
        }
      });
    }
  }

  handleURLChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } }, async () => {
      if (URL_REGEXP.test(value)) {
        const loadImage = AwesomeDebouncePromise(this.getImageBlob, 500);
        const result = await loadImage(value);
        if (result.type === 'image/png' || result.type === 'image/jpeg' || (id === 'favicon' && result.type === 'image/ico')) {
          const fileName = `${uuidv4()}.${result.type.split('/').pop()}`;
          this.blobToFile(result, fileName, id);
        }
      }
    });
  }

  getImageBlob = async (imageUrl: string) => {
    this.setState({ isFetching: true });
    const result = await fetch(`${API_URL}/fetch_image?url=${imageUrl}`, { headers: { 'Authorization': `Bearer ${this.props.authenticatedUser.token}` } });
    this.setState({ isFetching: false });
    return result.blob();
  }

  blobToFile = (blob: Blob, fileName: string, id: string) => {
    const fd = new FormData();
    fd.append('a', blob, fileName);
    this.setState({ isFetching: true, form: { ...this.state.form, [id]: fd.get('a') } }, async () => {
      await this.props.getUploadURL('logo', fileName);
      this.setState({ isFetching: false });
    });
  }

  switchPreview = () => this.setState((prevState: State) => ({ isPreviewActive: !prevState.isPreviewActive }), this.props.previewCallback);

  changeTemplateTab = (tab: string, template: string, e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ [template]: tab })
  }

  resetLogo = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ form: { ...this.state.form, logo_url: '' }, previewImage: null });
  }

  resetFavicon = (e: MouseEvent) => {
    e.stopPropagation();

    this.setState({ form: { ...this.state.form, favicon: '' }, favionImage: null }, () => {
      isFaviconUploaded = false;
    });
  }

  handleSelectChange = (partnerType: any) => {
    this.setState({ form: { ...this.state.form, partner_type: partnerType.value } });
  }


  render() {
    // const partnerTypeValue = partnerTypes.find(partnerType => partnerType.value === this.state.form.partner_type);

    const isFetching = this.props.isFetching || this.state.isFetching;

    if (this.state.form.logo_url) {
      if (typeof this.state.form.logo_url === 'string') {
        this.setState({ previewImage: this.state.form.logo_url });
      } else {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => this.setState({ previewImage: e.target.result });
        reader.readAsDataURL(this.state.form.logo_url);
      }
    }

    if (this.state.form.favicon) {
      if (typeof this.state.form.favicon === 'string') {
        this.setState({ favionImage: this.state.form.favicon });
      } else {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => this.setState({ favionImage: e.target.result });
        reader.readAsDataURL(this.state.form.favicon);
      }
    }

    const preview = () => {
      return [<div className='preview' key='0'>
        <header className='preview_header'>
          {this.state.previewImage && this.state.previewImage !== '' && <img src={this.state.previewImage} />}
          {!this.state.previewImage && <img src={Branding.COMPANY_LOGO} />}
          <span>{this.state.form.application_name || `${Branding.APP_NAME}`}</span>
        </header>
        <section>
          <aside className='preview_sidebar' style={{ backgroundColor: this.state.form.primary_css_color || '#004B87' }}>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </aside>
          <div className='preview_conversations'>
            <header>
              <span></span>
              <span></span>
            </header>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className='preview_chat'>
            <header>
              <span></span>
              <span></span>
            </header>
            <section>
              <div style={{ backgroundColor: this.state.form.primary_css_color || '#004B87' }}></div>
              <div className='me' style={{ borderColor: this.state.form.primary_css_color || '#004B87' }}></div>
            </section>
            <footer>
              <ul style={{ color: this.state.form.primary_css_color || '#004B87' }}>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <i style={{ backgroundColor: this.state.form.primary_css_color || '#004B87' }}></i>
            </footer>
          </div>
        </section>
      </div>, <div className="prt-form-field preview-buttons" key='1'>
        <button className="btn-secondary" type="button" onClick={this.switchPreview}>Back</button>
      </div>];
    };

    return (this.state.isPreviewActive ? preview() :
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        {this.props.errors && this.props.errors.length > 0 && (<div className='prt-form-field'>
          <div className="full-width error-msg">
            {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}
        {/* <Select
          name="status"
          label="Status"
          className="selector partnerType"
          onChange={this.handleSelectChange}
          value={partnerTypeValue}
          options={partnerTypes}
          isSearchable={false}
        /> */}
        <PartnerType partnerId={this.props.partnerId}/>
        {this.props.activeTab === 'customize' ? <>
          <div className="prt-form-field">
            <div className="prt-form-cell one-half">
              <ThemedInput
                autoFocus
                id="application_name"
                label="Application Name"
                onChange={this.handleFieldChange}
                placeholder={`e.g. ${Branding.APP_NAME}`}
                required
                type="text"
                validation="required"
                value={this.state.form.application_name}
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="domain_name"
                label="URL"
                onChange={this.handleFieldChange}
                placeholder="Type domain name"
                required
                type="text"
                validation="fqdn"
                value={this.state.form.domain_name}
              />
            </div>
          </div>
          <div className="prt-form-field">
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="cc_name"
                label="Admin Center Name"
                onChange={this.handleFieldChange}
                placeholder={`e.g. ${Branding.ADMIN_APP_NAME}`}
                required
                type="text"
                validation="required"
                value={this.state.form.cc_name}
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                className="prt-text-field"
                id="cc_domain_name"
                label="Admin Center URL"
                onChange={this.handleFieldChange}
                placeholder="Type domain name"
                required
                type="text"
                validation="fqdn"
                value={this.state.form.cc_domain_name}
              />
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="support_email"
                label="Support Email"
                onChange={this.handleFieldChange}
                placeholder="Type support email"
                required
                type="text"
                validation="email"
                value={this.state.form.support_email}
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="support_phone"
                label="Support Phone Number"
                onChange={this.handleFieldChange}
                placeholder="Type support phone number"
                required
                maxLength={16}
                type="text"
                validation="phone"
                value={this.state.form.support_phone}
              />
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell two-third">
              <label className="mandatory" htmlFor="logo_url">Logo</label>
              {this.state.previewImage ? <>
                <img id="logo_to_upload" src={this.state.previewImage} />
                <button id="logo_reset_btn" type="button" onClick={this.resetLogo}>
                  <i className="icon-close"></i>
                </button>
              </> : <>
                {this.state.logoType === CUSTOMIZE_LOGO_FILE ? <input
                  className="prt-text-field"
                  id="logo_url"
                  onChange={this.handleFileChange}
                  placeholder="Choose File..."
                  required
                  type="file"
                /> : <ThemedInput
                  id="logo_url"
                  onChange={this.handleURLChange}
                  placeholder="Type image URL..."
                  required
                  type="text"
                  validation="url"
                  value={this.state.form.logo_url}
                />}
                <div>
                  <ThemedRadio
                    checked={this.state.logoType === CUSTOMIZE_LOGO_FILE}
                    id='assign_number_new'
                    name='assign_number'
                    onChange={this.handleRadio}
                    text='Upload Image'
                    value={'' + CUSTOMIZE_LOGO_FILE}
                  />
                  <ThemedRadio
                    checked={this.state.logoType === CUSTOMIZE_LOGO_URL}
                    id='customize-logo-url'
                    name='assign_number'
                    onChange={this.handleRadio}
                    text='Image URL'
                    value={`CUSTOMIZE_LOGO_URL`}
                  />
                  <p className='field-help'>Supported image formats: JPG, PNG. Maximum image size is 5Mb.</p>
                </div>
              </>}
            </div>
            <div className="prt-form-cell one-third color-cell">
              <label className="mandatory" htmlFor="primary_css_color">Primary Color</label>
              <div className="prt-text-field" onClick={this.handleClickColorPicker}>
                <span style={{ backgroundColor: this.state.form.primary_css_color }}></span> {this.state.form.primary_css_color.toUpperCase()}
              </div>
              {this.state.isColorPickerOpen ? <div className="color-wrapper">
                <div className="color-cover" onClick={this.handleCloseColorPicker} />
                <ChromePicker disableAlpha={true} onChange={this.handleChangeComplete} color={this.state.form.primary_css_color} />
              </div> : null}
              <p className='field-help'>Will be used for buttons, links, etc.</p>
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell two-third">
              <label className="" htmlFor="logo_url">Favicon</label>
              {this.state.favionImage ? <>
                <img height="32" width="32" id="logo_to_upload" src={this.state.favionImage} />
                <button id="logo_reset_btn" type="button" onClick={this.resetFavicon}>
                  <i className="icon-close"></i>
                </button>
              </> : <>
                {this.state.faviconType === CUSTOMIZE_LOGO_FILE ? <input
                  className="prt-text-field"
                  id="favicon"
                  onChange={this.handleFileChange}
                  placeholder="Choose File..."
                  required
                  type="file"
                /> : <ThemedInput
                  id="favicon"
                  onChange={this.handleURLChange}
                  placeholder="Type image URL..."
                  required
                  type="text"
                  validation="url"
                  value={this.state.form.favicon}
                />}
                <div>
                  <ThemedRadio
                    checked={this.state.faviconType === CUSTOMIZE_LOGO_FILE}
                    id='assign_number_new1'
                    name='assign_number1'
                    onChange={this.handleRadio1}
                    text='Upload Image'
                    value={'' + CUSTOMIZE_LOGO_FILE}
                  />
                  <ThemedRadio
                    checked={this.state.faviconType === CUSTOMIZE_LOGO_URL}
                    id='customize-logo-url1'
                    name='assign_number1'
                    onChange={this.handleRadio1}
                    text='Image URL'
                    value={`CUSTOMIZE_LOGO_URL`}
                  />
                  <p className='field-help'>Supported image formats: JPG, PNG, ICO. Maximum image size is 5Mb.</p>
                </div>
              </>}
            </div>
          </div>

          {this.props.authenticatedUser.user_level === "administrator" && <div className="prt-form-field">
            <div className="prt-form-cell one-half">
              <label htmlFor="logo_url">Include 'Powered by {`${Branding.COMPANY_NAME}`}' for:</label>
              <div>
                <ThemedCheckbox
                  checked={this.state.form.show_login_logo_cc}
                  id="show_login_logo_cc"
                  onChange={this.handleCheckboxChange}
                  text="Admin Login Screen"
                />
                <ThemedCheckbox
                  checked={this.state.form.show_logo_cc}
                  id="show_logo_cc"
                  onChange={this.handleCheckboxChange}
                  text={`${Branding.ADMIN_APP_NAME}`}
                />
              </div>
              <div>
                <ThemedCheckbox
                  checked={this.state.form.show_login_logo_mv}
                  id="show_login_logo_mv"
                  onChange={this.handleCheckboxChange}
                  text="SC Login Screen"
                />
                <ThemedCheckbox
                  checked={this.state.form.show_logo_mv}
                  id="show_logo_mv"
                  onChange={this.handleCheckboxChange}
                  text={`${Branding.APP_NAME}`}
                />
              </div>
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="welcome_message"
                onChange={this.handleFieldChange}
                label="Welcome Message"
                placeholder="Type Welcome Message"
                tag="textarea"
                value={this.state.form.welcome_message}
              />
            </div>
            <div className="prt-form-cell one-half">
              <label htmlFor="logo_url">Include for:</label>
              <div>
                <ThemedCheckbox
                  checked={this.state.form.show_copywrite}
                  id="show_copywrite"
                  onChange={this.handleCheckboxChange}
                  text="Copywrite"
                />
                <ThemedCheckbox
                  checked={this.state.form.show_terms_of_use_link}
                  id="show_terms_of_use_link"
                  onChange={this.handleCheckboxChange}
                  text="Terms of Use link"
                />
              </div>
              <div>
                <ThemedCheckbox
                  checked={this.state.form.show_privacy_policy_link}
                  id="show_privacy_policy_link"
                  onChange={this.handleCheckboxChange}
                  text="Privacy Policy link"
                />
                <ThemedCheckbox
                  checked={this.state.form.show_help_link}
                  id="show_help_link"
                  onChange={this.handleCheckboxChange}
                  text="Help link"
                />
              </div>
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="privacy_policy_link_url"
                label="Privacy Policy Link URL"
                onChange={this.handleFieldChange}
                placeholder="Type Privacy Policy Link URL"
                validation="url"
                maxLength={500}
                type="text"
                value={this.state.form.privacy_policy_link_url}
              />
            </div>

          </div>}
        </> : <>
          <div className="prt-form-field">
            <div className="prt-form-cell full-width">
              <small>Please ensure sending emails from external software is enabled in SMTP service</small>
            </div>
            <div className="prt-form-cell one-fourth" style={{ verticalAlign: 'top' }}>
              <ThemedInput
                autoFocus
                id="smtp_host"
                label="Host"
                onChange={this.handleFieldChange}
                // required
                type="text"
                validation="fqdn"
                value={this.state.form.smtp_host}
              />
            </div>
            <div className="prt-form-cell one-fourth">
              <ThemedInput
                id="smtp_port"
                label="Port"
                onChange={this.handleFieldChange}
                // required
                maxLength={5}
                type="text"
                validation="port"
                value={this.state.form.smtp_port}
              />
            </div>
            <div className="prt-form-cell one-fourth">
              <ThemedInput
                id="smtp_user"
                label="User"
                onChange={this.handleFieldChange}
                // required
                type="text"
                validation="required"
                value={this.state.form.smtp_user}
              />
            </div>
            <div className="prt-form-cell one-fourth">
              <ThemedInput
                id="smtp_password"
                label="Password"
                onChange={this.handleFieldChange}
                // required
                type="password"
                validation="required"
                value={this.state.form.smtp_password}
              />
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="default_from_email"
                label='Default "From" Email'
                onChange={this.handleFieldChange}
                // required
                type="email"
                validation="email"
                value={this.state.form.default_from_email}
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                id="default_from_name"
                label='Default "From" Name'
                onChange={this.handleFieldChange}
                // required
                type="text"
                validation="required"
                value={this.state.form.default_from_name}
              />
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell full-width">
              <ul className="prt-tabs">
                <ThemedTab
                  className={classNames({ active: this.state.emailTemplateMode === 'preview' })}
                  onClick={this.changeTemplateTab.bind(this, 'preview', 'emailTemplateMode')}
                >
                  <a href="#">Preview</a>
                </ThemedTab>
                <ThemedTab
                  className={classNames({ active: this.state.emailTemplateMode === 'edit' })}
                  onClick={this.changeTemplateTab.bind(this, 'edit', 'emailTemplateMode')}
                >
                  <a href="#">Edit</a>
                </ThemedTab>
              </ul>
              <label className="mandatory" htmlFor="invitation_email_text">Intivation Email (for the Application)</label>
              {this.state.emailTemplateMode === 'edit' ? <>
                <ThemedInput
                  className="prt-text-field"
                  id="email_subject"
                  onChange={this.handleFieldChange}
                  placeholder="Subject"
                  required
                  type="text"
                  validation="required"
                  value={this.state.form.email_subject}
                />
                <ThemedInput
                  id="invitation_email_text"
                  onChange={this.handleFieldChange}
                  placeholder="Type invitation email text here..."
                  required
                  tag="textarea"
                  validation="required"
                  value={this.state.form.invitation_email_text}
                />
              </> : <div className="email_preview">
                <header>{this.state.form.email_subject_preview}</header>
                <div>
                  Hello <i>first_name</i>,<br /><br />
                  For the first login, use the following credentials:<br /><br />
                  URL: <i>messenger_url</i><br />
                  Login: <i>email</i><br />
                  Temporary Password: <i>password</i><br /><br />
                  <pre>{this.state.form.invitation_email_text_preview}</pre>
                </div>
              </div>}
            </div>
          </div>

          <div className="prt-form-field">
            <div className="prt-form-cell full-width">
              <ul className="prt-tabs">
                <ThemedTab
                  className={classNames({ active: this.state.emailCCTemplateMode === 'preview' })}
                  onClick={this.changeTemplateTab.bind(this, 'preview', 'emailCCTemplateMode')}
                >
                  <a href="#">Preview</a>
                </ThemedTab>
                <ThemedTab
                  className={classNames({ active: this.state.emailCCTemplateMode === 'edit' })}
                  onClick={this.changeTemplateTab.bind(this, 'edit', 'emailCCTemplateMode')}
                >
                  <a href="#">Edit</a>
                </ThemedTab>
              </ul>
              <label className="mandatory" htmlFor="cc_invitation_email_text">Intivation Email (for the Command Center)</label>
              {this.state.emailCCTemplateMode === 'edit' ? <>
                <ThemedInput
                  className="prt-text-field"
                  id="cc_email_subject"
                  onChange={this.handleFieldChange}
                  placeholder="Subject"
                  required
                  type="text"
                  validation="required"
                  value={this.state.form.cc_email_subject}
                />
                <ThemedInput
                  id="cc_invitation_email_text"
                  onChange={this.handleFieldChange}
                  placeholder="Type invitation email text here..."
                  required
                  tag="textarea"
                  validation="required"
                  value={this.state.form.cc_invitation_email_text}
                />
              </> : <div className='email_preview'>
                <header>{this.state.form.cc_email_subject_preview}</header>
                <div>
                  Hello <i>first_name</i>,<br /><br />
                  For the first login, use the following credentials:<br /><br />
                  URL: <i>messenger_url</i><br />
                  Login: <i>email</i><br />
                  Temporary Password: <i>password</i><br /><br />
                  <pre>{this.state.form.cc_invitation_email_text_preview}</pre>
                </div>
              </div>}
            </div>
          </div>
        </>}

        <div className="prt-form-field">
          {this.props.activeTab === 'email_setup' && <button
            className="btn-primary"
            disabled={isFetching
              || !this.state.form.application_name
              || !FQDN_REGEXP.test(this.state.form.cc_domain_name)
              || !this.state.form.cc_email_subject
              || !this.state.form.cc_invitation_email_text
              || !this.state.form.cc_name
              || (this.state.form.default_from_email && !EMAIL_REGEXP.test(this.state.form.default_from_email) ? true : false)
              // || !EMAIL_REGEXP.test(this.state.form.default_from_email)
              // || !this.state.form.default_from_name
              || !FQDN_REGEXP.test(this.state.form.domain_name)
              || !this.state.form.email_subject
              || !this.state.form.invitation_email_text
              || !this.state.form.logo_url
              || !this.state.form.primary_css_color
              || this.state.form.primary_css_color.charAt(0) !== '#'
              || (this.state.form.smtp_host && !FQDN_REGEXP.test(this.state.form.smtp_host) ? true : false)
              // || !FQDN_REGEXP.test(this.state.form.smtp_host)
              // || !this.state.form.smtp_password
              // || !this.state.form.smtp_port
              // || !this.state.form.smtp_user
              || !EMAIL_REGEXP.test(this.state.form.support_email)
              || !this.state.form.support_phone
            }
            type="submit">{isFetching ? <Loader /> : 'Apply'}</button>}
          {this.props.activeTab === 'customize' && <button className="btn-primary" type="button" onClick={this.props.nextTab}>Next</button>}
          {this.props.activeTab === 'email_setup' && <button className="btn-secondary is-cancel" type="button" onClick={this.props.backTab}>Back</button>}
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
          <button className="btn-secondary is-right is-text is-danger" type="button" onClick={this.handleReset}>Reset to Default</button>
          {this.props.activeTab === 'customize' && <button className="btn-secondary is-right is-text is-primary" type="button" onClick={this.switchPreview}>Preview</button>}
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizeForm));
